<template>
  <div style="width: 1100px;margin: 0 auto;" class="flex flex-h-center">
    <div ref="box" class="media-box none-border">
      <el-card>
        <!-- <el-page-header @back="goBack" slot="header">
          <h3 slot="content">{{ info.title }}</h3>
        </el-page-header> -->

        <el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{$t('首页')}}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/article#' + info.type_id }" v-if="tag == 'article'">
            {{$t('资讯详情')}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ info.title }}</el-breadcrumb-item>
        </el-breadcrumb>

        <div v-html="info.content"></div>
      </el-card>
    </div>

    <div style="width:255px;">
    </div>
    <el-card class="aside" shadow="never" style="width:255px;" v-if="$store.state.mode == 'CCL'">

      <el-card shadow="never" class="hover" style="background: url(/web/image/home/4-3.png);color: #fff;width: 210px;">
        <div @click="to('/media?tag=course&id=5')">
          <h4>CCL {{$t('冲刺保过班')}}</h4>
          <p class="st1 margin-ts color-white">{{$t('配合9大内容精讲，一一剖析，全程真题演练')}}</p>
        </div>
      </el-card>


      <el-card shadow="hover" class="margin-ts hover"
        style="background: url(/web/image/home/4-3.png);color: #fff;width: 210px;">
        <div @click="to('/media?tag=course&id=6')">
          <h4>CCL {{$t('精品1v1私人订制课程')}}</h4>
          <p class="st1 margin-ts color-white">{{$t('顶级名师一对一前程亲自辅导指点')}}</p>
        </div>
      </el-card>
      <el-image :src="$host + $store.state.setting.kf_qrcode" style="width:210px;height:210px;margin-top: 10px;" />

      <div class="st center">{{$t('扫码咨询课程')}}</div>
    </el-card>


    <el-card class="aside" shadow="never" style="width:255px;" v-else>

      <el-card shadow="never" class="hover" style="background: url(/web/image/home/4-1.png);color: #fff;width: 210px;">
        <div @click="to('/media?tag=course&id=3')">
          <h4>{{$t('基础技巧精讲班')}}</h4>
          <p class="st1 margin-ts color-white">{{$t('从零开始了解PTE听说读写四项题型技巧')}}</p>
        </div>
      </el-card>


      <el-card shadow="hover" class="margin-ts hover"
        style="background: url(/web/image/home/4-4.png);color: #fff;width: 210px;">
        <div @click="to('/media?tag=course&id=4')">
          <h4>{{$t('全项机经刷题班')}}</h4>
          <p class="st1 margin-ts color-white">{{$t('练习课为高密度带练刷高频预测，快速提高做题能力')}}</p>
        </div>
      </el-card>

      <el-card shadow="never" class="margin-ts hover"
        style="background: url(/web/image/home/4-3.png);color: #fff;width: 210px;">
        <div @click="to('/media?tag=course&id=1')">
          <h4>{{$t('技巧刷题保过班')}}</h4>
          <p class="st1 margin-ts color-white">{{$t('包含20小时基础技巧课+40小时全项机经刷题课')}}</p>
        </div>
      </el-card>

      <el-card shadow="never" class="margin-ts hover"
        style="background: url(/web/image/home/4-2.png);color: #fff;width: 210px;">
        <div @click="to('/media?tag=course&id=2')">
          <h4>1v1 {{$t('VIP私人订制课程')}}</h4>
          <p class="st1 margin-ts color-white">{{$t('顶级名师一对一全程亲自辅导指点')}}</p>
        </div>
      </el-card>

      <el-image :src="$host + $store.state.setting.kf_qrcode" style="width:210px;height:210px;margin-top: 10px;" />

      <div class="st center">{{$t('扫码咨询课程')}}</div>
    </el-card>
  </div>
</template>

<script>
export default {

  data: function () {
    return {
      tag: "",
      id: 0,
      info: {},
    }
  },
  created() {
    this.load();
  },




  watch: {
    $route() {
      this.load();
    }
  },

  methods: {

    scroll(e) {
      console.log(e.scrollTop)
    },

    to(url) {
      this.$router.replace(url)
    },

    load() {
      this.tag = this.$route.query.tag;
      this.id = this.$route.query.id
      let info = this.$store.getters.getMedia(this.tag, this.id);
      if (info) {
        this.info = info;
        this.$nextTick(() => {
          this.$bus.$emit("scrollTop", 0);
        })
      } else {
        this.query()
      }
    },


    goBack() {
      this.$router.back();
    },
    query() {
      this.$http.post("/api/" + this.tag + "/info", { id: this.id }).then((info) => {
        this.$store.commit("saveMedia", [this.tag, info])
        this.info = info;
        this.$nextTick(() => {
          this.$bus.$emit("scrollTop", 0);
        })
      })
    },
  },
};
</script>

<style scoped>
.aside {
  position: fixed;
  height: 670px;
  margin-top: 40px;
  left: 50%;
  margin-left: 295.5px;
  top: 80px;
}

.aside>>>.el-card__body {
  padding: 10px 20px;
}

.media-box {
  width: 800px;
  margin: 40px auto;
  position: relative;
  overflow-x: hidden;
}

.media-box>>>.el-card {
  border: none !important;
}

/* 
.media-box>>>.el-card__body {
  padding: 0;
} */

.hover:hover {
  cursor: pointer;
  border: 1px solid #f2f2f2;
  box-shadow: #cccccc 0px 0px 6px 4px;
}
</style>
